import {
  FormArray,
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { Utilities } from "./utils";
import { ConversationViewComponent } from "../features/newsfeed/conversation-view/conversation-view.component";

export class Conversation {
  public rootPostId: string;
  public rootPost: Post;
  public replies: Post[];
  public users: UserInfo[];
  public hashtags: Hashtag[];
  public isFavorite: boolean;
  public ik: IkInfo;
  public idea: IdeaInfo;
  public survey?: PostSurvey;
  public responses?: SurveyResponse[];
  public isFavourite: boolean;
  public privateNetworkId: string;
  public maxDate: Date;
  public view: ConversationViewComponent;

  constructor(initialData?: any) {
    if (initialData) {
      this.rootPostId = initialData.rootPostId;
      this.rootPost = new Post(initialData.rootPost);
      this.replies = initialData.replies
        ? initialData.replies.map(p => new Post(p))
        : [];
      this.users = initialData.users
        ? initialData.users.map(u => new UserInfo(u))
        : [];
      this.hashtags = initialData.hashtags
        ? initialData.hashtags.map(h => new Hashtag(this.hashtags))
        : [];
      this.ik = new IkInfo(initialData.ik);
      this.idea = new IdeaInfo(initialData.ik);
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      rootPostId: [this.rootPostId],
      rootPost: this.rootPost.toFormGroup(builder),
      replies: this.createPostArray(this.replies, builder),
      users: this.createUserInfoArray(this.users, builder),
      hashtags: this.createHastagArray(this.hashtags, builder),
      ik: this.ik.toFormGroup(builder),
      idea: this.idea.toFormGroup(builder)
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createHastagArray(list: Hashtag[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }

  createPostArray(list: Post[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(p => {
      results.push(p.toFormGroup(builder));
    });

    return results;
  }
}

export class Post {
  public id: string;
  public postId: string;
  public type: number;
  public isRoot: boolean;
  public rootPostId: string;
  public plainContent: string;
  public fullContent: string;
  public locked: boolean;
  public isBestAnswer: boolean;
  public attachments: Attachment[];
  public created: Date;
  public createdBy: string;
  public modified: Date;
  public modifiedBy: string;
  public deleted: Date;
  public deletedBy: string;
  public likes: string[];
  public hashtags: string[];
  public newHashtags?: any[];
  public mentions: string[];
  public audience: string[];
  public isDeleted: boolean;
  public isOfInterest: boolean;

  public postAudiences: AudienceInfo[];
  public survey?: PostSurvey;

  public votes: Vote[];

  public viewCount: number;

  public otherAuthor: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.postId = initialData.postId;
      this.type = initialData.type;
      this.isRoot = initialData.isRoot;
      this.rootPostId = initialData.rootPostId;
      this.plainContent = initialData.plainContent;
      this.fullContent = initialData.fullContent;
      this.locked = initialData.locked;
      this.isBestAnswer = initialData.isBestAnswer;
      this.attachments = initialData.attachments
        ? initialData.attachments.map(a => new Attachment(a))
        : [];
      this.created = initialData.created;
      this.createdBy = initialData.createdBy;
      this.modified = initialData.modified;
      this.modifiedBy = initialData.modifiedBy;
      this.likes = initialData.likes;
      this.hashtags = initialData.hashtags;
      this.mentions = initialData.mentions;
      this.audience = initialData.audience;
      this.isDeleted = initialData.isDeleted;
      this.isOfInterest = initialData.isOfInterest;
      this.postAudiences = initialData.postAudiences
        ? initialData.postAudiences.map(x => new AudienceInfo(x))
        : [];
      this.votes = initialData.votes
        ? initialData.votes.map(v => new Vote(v))
        : [];
      this.viewCount = initialData.viewCount ? initialData.viewCount : 0;
      this.otherAuthor = initialData.otherAuthor;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      postId: [this.postId],
      type: [this.type],
      isRoot: [this.isRoot],
      rootPostId: [this.rootPostId],
      plainContent: [this.plainContent],
      fullContent: [this.fullContent],
      locked: [this.locked],
      isBestAnswer: [this.isBestAnswer],
      attachments: this.createAttachmentsArray(this.attachments || [], builder),
      created: [this.created],
      createdBy: [this.createdBy],
      modified: [this.modified],
      modifiedBy: [this.modifiedBy],
      likes: this.createStringArray(this.likes || [], builder),
      hashtags: this.createStringArray(this.hashtags || [], builder),
      mentions: this.createStringArray(this.mentions || [], builder),
      audience: this.createStringArray(this.audience || [], builder),
      isDeleted: [this.isDeleted],
      isOfInterest: [this.isOfInterest],
      postAudiences: this.createAudienceInfoArray(
        this.postAudiences || [],
        builder
      ),
      votes: this.createVotersArray(this.votes || [], builder),
      viewCount: [this.viewCount],
      otherAuthor: [this.otherAuthor]
    });
  }

  createAttachmentsArray(list: Attachment[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }

  createAudienceInfoArray(list: AudienceInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(s.toFormGroup(builder));
    });

    return results;
  }

  createVotersArray(list: Vote[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(s.toFormGroup(builder));
    });

    return results;
  }
}

export class Vote {
  public id: string;
  public objectId: string;
  public user: UserInfo;
  public type: UserType;
  public objectType: EvaluationObjectType;
  public created: Date;
  public modified: Date;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.objectId = initialData.objectId;
      this.user = initialData.user ? new UserInfo(initialData.user) : null;
      this.type = initialData.type;
      this.objectType = initialData.objectType;
      this.created = initialData.created;
      this.modified = initialData.modified;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      objectId: [this.objectId],
      user: this.user
        ? this.user.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      type: [this.type],
      objectType: [this.objectType],
      created: [this.created],
      modified: [this.modified]
    });
  }
}

export class ExternalUser {
  public id: string;
  public displayName: string;
  public lastName: string;
  public firstName: string;
  public email: string;
  public upn: string;
  public employeeReference: UserInfo;
  public businessUnit: BusinessUnit;
  public company: string;
  public city: string;
  public isLeleEnabled: boolean; 
  public networks: any; 
  public assignedCops: string;
  public assignedBU: string;
  public assignedSpTags: string;
  public assignedPN: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.upn = initialData.upn;
      this.email = initialData.email;
      this.displayName = initialData.displayName;
      this.lastName = initialData.lastName;
      this.firstName = initialData.firstName;
      this.employeeReference = new UserInfo({
        userId: initialData.managerId,
        displayName: initialData.manager
      });
      this.businessUnit = new BusinessUnit({
        businessUnitId: initialData.businessUnitId,
        name: initialData.businessUnit
      });
      this.company = initialData.company;
      this.city = initialData.city;
      this.isLeleEnabled = initialData.isLeleEnabled;
      this.networks = initialData.networks;
    }
  }
}

// export interface Role {
//   scope: string;
//   description: string;
// }

export class User {
  // public id: string;
  public userId: string;
  public upn: string;
  public displayName: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public avatarUrl: string;
  public followedPeople: UserInfo[];
  public followedHashtags: Hashtag[];
  public manager: UserInfo;
  public businessUnit: BusinessUnit;
  public unitCode: string;
  public orgaUnitCode: string;
  public aboutMe: string;
  public knowledgeExperience: string;
  public city: string;
  public country: string;
  public company: string;
  public disclaimerAccepted: boolean;
  public disclaimerAcceptedDate: Date;
  public isExternalConsultant: boolean;
  public societyBusiness: string;
  public professionalArea: string;
  public phone: string;
  public skypeForBusiness: string;
  public askMeAbout: string;
  // public roles: string[];
  public preferences: Preferences;
  public skills: HashtagInfo[];
  public coverUrl: string;
  public isKOEligible: boolean;
  public roles: Role;
  public businessRole: string;
  public category: UserCategory;
  public hasCompletedWelcomeExperience: boolean;
  public onPremisesImmutableId: string;

  public isDeleted: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      // this.id = initialData.id;
      this.isDeleted = initialData.isDeleted;
      this.userId = initialData.userId;
      this.upn = initialData.upn;
      this.displayName = initialData.displayName;
      this.firstName = initialData.firstName;
      this.lastName = initialData.lastName;
      this.email = initialData.email;
      this.avatarUrl = initialData.avatarUrl;
      this.followedPeople = initialData.followedPeople
        ? initialData.followedPeople.map(u => new UserInfo(u))
        : [];
      this.followedHashtags = initialData.followedHashtags
        ? initialData.followedHashtags.map(u => new HashtagInfo(u))
        : [];
      this.manager = new UserInfo(initialData.manager);
      this.businessUnit = new BusinessUnit(initialData.businessUnit);
      this.unitCode = initialData.unitCode;
      this.orgaUnitCode = initialData.orgaUnitCode;
      this.aboutMe = initialData.aboutMe;
      this.knowledgeExperience = initialData.knowledgeExperience;
      this.city = initialData.city;
      this.country = initialData.country;
      this.company = initialData.company;
      this.disclaimerAccepted = initialData.disclaimerAccepted;
      this.disclaimerAcceptedDate = initialData.disclaimerAcceptedDate;
      this.isExternalConsultant = initialData.isExternalConsultant;
      this.societyBusiness = initialData.societyBusiness;
      this.professionalArea = initialData.professionalArea;
      this.phone = initialData.phone;
      this.skypeForBusiness = initialData.skypeForBusiness;
      // this.roles = initialData.roles;
      this.askMeAbout = initialData.askMeAbout;
      this.preferences = new Preferences(initialData.preferences);
      this.skills = initialData.skills
        ? initialData.skills.map(x => new HashtagInfo(x))
        : [];
      this.coverUrl = initialData.coverUrl;
      this.roles = initialData.roles
        ? new Role(initialData.roles)
        : new Role({ userId: this.userId, upn: this.upn });
      this.isKOEligible = initialData.isKOEligible;
      this.businessRole = initialData.businessRole;
      this.category = initialData.category;
      this.hasCompletedWelcomeExperience =
        initialData.hasCompletedWelcomeExperience;
      this.onPremisesImmutableId = initialData.onPremisesImmutableId;
    }
  }

  static createUserArray(list: User[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  mapRoleToString(): string[] {
    const roles: string[] = [];

    if (!this.roles) {
      return roles;
    }

    if (this.roles.admin) {
      roles.push(RoleNameVariable.Admin);
    }
    if (this.roles.kmTeam) {
      roles.push(RoleNameVariable.KMSTeam);
    }
    if (this.roles.champion) {
      roles.push(RoleNameVariable.Champion);
    }

    if (this.roles.webinarTeam) {
      roles.push(RoleNameVariable.WebinarTeam);
    }

    this.roles.copFacilitatorScopes.forEach(copId => {
      roles.push(`${RoleNameVariable.Facilitator}_${copId}`);
    });
    this.roles.copCoFacilitatorScopes.forEach(copId => {
      roles.push(`${RoleNameVariable.CoFacilitator}_${copId}`);
    });
    this.roles.buFocalPointScopes.forEach(copId => {
      roles.push(`${RoleNameVariable.FocalPoint}_${copId}`);
    });
    this.roles.buReferentScopes.forEach(copId => {
      roles.push(`${RoleNameVariable.KMRefetent}_${copId}`);
    });
    this.roles.koScopes.forEach(hashtagId => {
      roles.push(`${RoleNameVariable.KnowledgeOwner}_${hashtagId}`);
    });

    this.roles.externalReferentScopes.forEach(networkId => {
      roles.push(`${RoleNameVariable.Facilitator}_${networkId}`);
    });
    this.roles.externalMemberScopes.forEach(networkId => {
      roles.push(`${RoleNameVariable.CoFacilitator}_${networkId}`);
    });

    this.roles.specialTagCoreTeamScopes.forEach(networkId => {
      roles.push(`${RoleNameVariable.CoreTeam}_${networkId}`);
    });
    this.roles.specialTagReferentScopes.forEach(networkId => {
      roles.push(`${RoleNameVariable.Referent}_${networkId}`);
    });

    return roles;
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      // id: [this.id],
      userId: [this.userId],
      upn: [this.upn, Validators.required],
      displayName: [this.displayName, Validators.required],
      firstName: [this.firstName],
      lastName: [this.lastName],
      email: [this.email, [Validators.required, Validators.email]],
      avatarUrl: [this.avatarUrl],
      followedPeople: this.createFollowedPeopleArray(
        this.followedPeople || [],
        builder
      ),
      followedHashtags: this.createFollowedHashtagArray(
        this.followedHashtags || [],
        builder
      ),
      manager: this.manager
        ? this.manager.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      businessUnit: this.businessUnit
        ? this.businessUnit.toFormGroup(builder)
        : new BusinessUnit().toFormGroup(builder),
      unitCode: [this.unitCode],
      orgaUnitCode: [this.orgaUnitCode],
      aboutMe: [this.aboutMe],
      knowledgeExperience: [this.knowledgeExperience],
      city: [this.city],
      country: [this.country],
      company: [this.company],
      disclaimerAccepted: [this.disclaimerAccepted],
      disclaimerAcceptedDate: [this.disclaimerAcceptedDate],
      isExternalConsultant: [this.isExternalConsultant || false],
      societyBusiness: [this.societyBusiness],
      professionalArea: [this.professionalArea],
      phone: [this.phone],
      skypeForBusiness: this.skypeForBusiness,
      // roles: this.createStringArray(this.roles || [], builder),
      askMeAbout: [this.askMeAbout],
      preferences: this.preferences
        ? this.preferences.toFormGroup(builder)
        : new Preferences().toFormGroup(builder),
      skills: this.createHashtagInfoArray(this.skills || [], builder),
      coverUrl: [this.coverUrl],
      roles: this.roles ? this.roles.toFormGroup(builder) : null,
      isKOEligible: [this.isKOEligible],
      businessRole: [this.businessRole],
      category: [this.category],
      hasCompletedWelcomeExperience: [this.hasCompletedWelcomeExperience],
      onPremisesImmutableId: [this.onPremisesImmutableId]
    });
  }

  createFollowedPeopleArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createFollowedHashtagArray(list: Hashtag[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class UserInfo {
  // public id: string;
  public userId: string;
  public displayName: string;
  public email: string;
  public avatarUrl: string;
  public isExternalConsultant: boolean;
  public roles: Role;
  public isKOEligible: boolean;
  public unitCode: string;

  constructor(initialData?: any) {
    if (initialData) {
      // this.id = initialData.id;
      this.userId = initialData.userId;
      this.displayName = initialData.displayName;
      this.email = initialData.email;
      this.avatarUrl = initialData.avatarUrl;
      this.isExternalConsultant = initialData.isExternalConsultant;
      this.roles = initialData.roles ? new Role(initialData.roles) : null;
      this.isKOEligible = initialData.isKOEligible;
      this.unitCode = initialData.unitCode;
    }
  }

  static createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      userId: [this.userId],
      displayName: [this.displayName],
      email: [this.email],
      avatarUrl: [this.avatarUrl],
      isExternalConsultant: [this.isExternalConsultant],
      roles: this.roles ? this.roles.toFormGroup(builder) : null,
      isKOEligible: [this.isKOEligible],
      unitCode: [this.unitCode]
    });
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
}

export class WebinarUserInfo extends UserInfo {
  public participants: number;

  constructor(initialData?: any) {
    super(initialData);
    if (initialData) {
      this.participants = initialData.participants;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      userId: [this.userId],
      displayName: [this.displayName],
      email: [this.email],
      avatarUrl: [this.avatarUrl],
      isExternalConsultant: [this.isExternalConsultant],
      roles: this.roles ? this.roles.toFormGroup(builder) : null,
      isKOEligible: [this.isKOEligible],
      unitCode: [this.unitCode],
      participants: [this.participants]
    });
  }
}

export class BusinessUnit {
  public businessUnitId: string;
  public name: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.name = initialData.name;
      this.businessUnitId = initialData.businessUnitId;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      businessUnitId: [this.businessUnitId],
      name: [this.name]
    });
  }
}

export class City {
  public name: string;
  public latitude: number;
  public longitude: number;
  public country: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.name = initialData.name;
      this.latitude = initialData.latitude;
      this.longitude = initialData.longitude;
      this.country = initialData.country;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      name: [this.name],
      latitude: [this.latitude],
      longitude: [this.longitude],
      country: [this.country]
    });
  }
}

export class Preferences {
  public preferredLanguage: string;
  public digestOption: DigestFrequency;

  constructor(initialData?: any) {
    if (initialData) {
      this.preferredLanguage = initialData.preferredLanguage;
      this.digestOption = initialData.digestOption;
    } else {
      this.digestOption = DigestFrequency.Weekly;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      preferredLanguage: [this.preferredLanguage],
      digestOption: [this.digestOption]
    });
  }
}

export class AISearchTelemetry
{
   public text: string;
   public suggestions: string[];
   public iks: string[];
}

export class Hashtag {
  public id: string;
  public hashtagId: string;
  public name: string;
  public type: number;
  public description: string;
  public isDeprecated: boolean;
  public isPromoted: boolean;
  public logoUrl: string;
  public relatedHashtags: HashtagInfo[];
  public owners: UserInfo[];
  public referenceYear: number[];
  public promotingNetworks: NetworkInfo[];
  public isPrivate: boolean;
  public isDeleted: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.hashtagId = initialData.hashtagId;
      this.name = initialData.name;
      this.type = initialData.type;
      this.description = initialData.description;
      this.isDeprecated = initialData.isDeprecated;
      this.isPromoted = initialData.isPromoted;
      this.logoUrl = initialData.logoUrl;
      this.isDeleted = initialData.isDeleted;
      this.relatedHashtags = (initialData.relatedHashtags || []).map(
        h => new HashtagInfo(h)
      );
      this.owners = initialData.owners
        ? initialData.owners.map(o => new UserInfo(o))
        : [];
      this.referenceYear = initialData.referenceYear
        ? initialData.referenceYear
        : [];
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p))
        : [];
      this.isPrivate = initialData.isPrivate;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      hashtagId: [this.hashtagId],
      name: [this.name, Validators.maxLength(100)],
      type: [this.type],
      description: [this.description],
      isDeprecated: [this.isDeprecated],
      isPromoted: [this.isPromoted],
      logoUrl: [this.logoUrl],
      relatedHashtags: this.createRelatedHashtagsArray(
        this.relatedHashtags || [],
        builder
      ),
      owners: this.createUserInfoArray(this.owners || [], builder),
      referenceYear: this.createNumberArray(this.referenceYear || [], builder),
      promotingNetworks: this.createNetworkInfoArray(
        this.promotingNetworks || [],
        builder
      ),
      isPrivate: [this.isPrivate]
    });
  }

  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }
  createNumberArray(list: number[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }

  createRelatedHashtagsArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}
export class HashtagInfo {
  public hashtagId: string;
  public name: string;
  public type: HashtagType = HashtagType.Generic;
  public description: string;
  public logoUrl: string;
  public owners: UserInfo[];
  public isPrivate: boolean;
  public isDeprecated: boolean;
  public id: string;
  public postCount :number;

  public promotingNetworksIds: Array<string>;

  constructor(initialData?: any) {
    if (initialData) {
      this.hashtagId = initialData.hashtagId;
      this.name = initialData.name;
      this.type = initialData.type;
      this.description = initialData.description;
      this.logoUrl = initialData.logoUrl;
      this.owners = initialData.owners
        ? initialData.owners.map(o => new UserInfo(o))
        : [];
      this.isPrivate = initialData.isPrivate;
      this.promotingNetworksIds = initialData.promotingNetworksIds;
      this.isDeprecated = initialData.isDeprecated;
      this.postCount = initialData?.postCount ? initialData?.postCount :0
    }
  }

  static createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      hashtagId: [this.hashtagId],
      name: [this.name, Validators.maxLength(100)],
      type: [this.type],
      description: [this.description],
      logoUrl: [this.logoUrl],
      postCount:0,
      owners: this.createUserInfoArray(this.owners || [], builder),
      isPrivate: [this.isPrivate]
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export interface HashtagBadge {
  followers: number;
  rootPostCount: number;
  replyCount: number;
  webinars: number;
  knowledgeNuggets: number;
  challenges: number;
  ideas: number;
  successStories: number;
}

export class IAttachment {
  public url: string;
  public name: string;
  public mimeType: string;
  public attachmentId: string;
}

export class Attachment {
  public url: string;
  public name: string;
  public mimeType: string;
  public id: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.url = initialData.url;
      this.name = initialData.name;
      this.id = initialData.id;
      this.mimeType = initialData.mimeType;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      url: [this.url],
      name: [this.name],
      mimeType: [this.id]
    });
  }
}

export class Disclaimer {
  public id: string;
  public activeSince: Date;
  public bestPractice: string;
  public content: string;
  public attachmentUrl: Array<string>;
}

export class NetworkInfo {
  public networkId: string;
  public logoUrl: string;
  public coverUrl: string;
  public name: string;
  public hashtag: HashtagInfo;
  public abstract: string;
  public type: NetworkType;
  public isPrivate: boolean;
  public status: string;
  public facilitatorsIds: string[];
  public coFacilitatorsIds: string[];
  public guestsIds: string[];
  
  constructor(initialData?: any) {
    if (initialData) {
      this.networkId = initialData.networkId;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.name = initialData.name;
      this.hashtag = initialData.hashtag instanceof HashtagInfo ? initialData.hashtag : new HashtagInfo(initialData.hashtag);
      this.abstract = initialData.abstract;
      this.type = initialData.type;
      this.isPrivate = initialData.isPrivate;
      this.status = initialData.status;
      this.facilitatorsIds = initialData.facilitatorsIds ? initialData.facilitatorsIds : [];
      this.coFacilitatorsIds = initialData.coFacilitatorsIds ? initialData.coFacilitatorsIds :[];
      this.guestsIds = initialData.guestsIds ? initialData.guestsIds : [];
    }
  }

  static createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    if(list){
      list.forEach(n => {
        results.push(n.toFormGroup(builder));
      });
    }
    return results;
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      networkId: [this.networkId],
      logoUrl: [this.logoUrl],
      coverUrl: [this.coverUrl],
      name: [this.name],
      hashtag: this.hashtag.toFormGroup(builder),
      abstract: [this.abstract],
      type: [this.type],
      isPrivate: [this.isPrivate],
      status:[this.status],
      facilitatorsIds:[this.facilitatorsIds],
      coFacilitatorsIds:[this.coFacilitatorsIds],
      guestsIds:[this.guestsIds]
    });
  }
}

export class Network {
  public networkId: string;
  public logoUrl: string;
  public coverUrl: string;
  public teamUrl: string;
  public name: string;
  public hashtag: HashtagInfo;
  // public focalPoints: UserInfo[];
  public facilitators: UserInfo[];
  public coFacilitators: UserInfo[];
  // public members: UserInfo[];
  public guests: UserInfo[];
  public koUsers: UserInfo[];
  public rootPostCount: number;
  public replyCount: number;
  public followerCounter: number;
  public webinarCount: number;
  public knowledgeNuggetCount: number;
  public challengeCount: number;
  public ideaCount: number;
  public avgContributors: number;
  public contributors: number;
  public abstract: string;
  public strategicTags: HashtagInfo[];
  public ourTags: HashtagInfo[];
  public stickyPosts: StickyPost[];
  public navLinks: NavLink[];
  public conversations: Conversation[];
  public allowExternalUsers: boolean;
  public isHidden: boolean;
  public created: Date;
  public createdBy: UserInfo;
  public modified: Date;
  public modifiedBy: UserInfo;
  public deleted: Date;
  public deletedBy: UserInfo;
  public isDeleted: boolean;
  public coreTeam: UserInfo[];
  public type: NetworkType;
  public navLinksTitle: string;
  public status: number;
  public attachments: IAttachment[];
  public successStoryCount: number;
  public isPrivate: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      this.networkId = initialData.networkId;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.status = initialData.status;
      this.teamUrl = initialData.teamUrl;
      this.name = initialData.name;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      // this.focalPoints = initialData.focalPoints.map(u => new UserInfo(u));
      this.facilitators = initialData.facilitators.map(u => new UserInfo(u));
      this.coFacilitators = initialData.coFacilitators.map(
        u => new UserInfo(u)
      );
      // this.members = initialData.members.map(u => new UserInfo(u));
      this.guests = initialData.guests
        ? initialData.guests.map(u => new UserInfo(u))
        : [];
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
      this.followerCounter = initialData.followerCounter;
      this.webinarCount = initialData.webinarCount;
      this.knowledgeNuggetCount = initialData.knowledgeNuggetCount;
      this.challengeCount = initialData.challengeCount;
      this.ideaCount = initialData.ideaCount;
      this.successStoryCount = initialData.successStoryCount;
      this.avgContributors = initialData.avgContributors;
      this.contributors = initialData.contributors;
      this.abstract = initialData.abstract;
      this.strategicTags = initialData.strategicTags
        ? initialData.strategicTags.filter(r => r.type === HashtagType.StrategicTag).map(h => new HashtagInfo(h))
        : [];
      this.ourTags = initialData.ourTags
        ? initialData.ourTags.map(h => new HashtagInfo(h))
        : [];
      this.stickyPosts = initialData.stickyPosts
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        })
        .map(s => new StickyPost(s));
      this.navLinks = initialData.navLinks.map(n => new NavLink(n));
      // public conversations: Conversation[];
      this.allowExternalUsers = initialData.allowExternalUsers;
      this.isHidden = initialData.isHidden;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
      this.isDeleted = initialData.isDeleted;
      this.coreTeam = initialData.coreTeam
        ? initialData.coreTeam.map(h => new UserInfo(h))
        : [];
      this.type = initialData.type;
      this.navLinksTitle = initialData.navLinksTitle;
      this.koUsers = initialData.koUsers.map(u => new UserInfo(u));
      this.attachments = initialData.attachments;
      this.isPrivate = initialData.isPrivate;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      networkId: [this.networkId],
      logoUrl: [this.logoUrl],
      coverUrl: [this.coverUrl],
      status: [this.status],
      teamUrl: [this.teamUrl],
      name: [this.name],
      hashtag: this.hashtag.toFormGroup(builder),
      // focalPoints: this.createUserInfoArray(this.focalPoints, builder),
      facilitators: this.createUserInfoArray(this.facilitators, builder),
      coFacilitators: this.createUserInfoArray(this.coFacilitators, builder),
      // members: this.createUserInfoArray(this.members, builder),
      guests: this.createUserInfoArray(this.guests, builder),
      rootPostCount: [this.rootPostCount],
      replyCount: [this.replyCount],
      followerCounter: [this.followerCounter],
      webinarCount: [this.webinarCount],
      knowledgeNuggetCount: [this.knowledgeNuggetCount],
      challengeCount: [this.challengeCount],
      ideaCount: [this.ideaCount],
      avgContributors: [this.avgContributors],
      contributors: [this.contributors],
      abstract: [this.abstract],
      successStoryCount: [this.successStoryCount],
      strategicTags: this.createHastagInfoArray(this.strategicTags, builder),
      ourTags: this.createHastagInfoArray(this.ourTags, builder),
      stickyPosts: this.createStickyPostArray(this.stickyPosts, builder),
      navLinks: this.createNavLinkArray(this.navLinks, builder),
      // conversations: this.createConversationArray(this.conversations, builder),
      allowExternalUsers: [this.allowExternalUsers],
      isHidden: [this.isHidden],
      created: [this.created],
      createdBy: this.createdBy.toFormGroup(builder),
      modified: [this.modified],
      modifiedBy: this.modifiedBy.toFormGroup(builder),
      deleted: [this.deleted],
      deletedBy: this.deletedBy.toFormGroup(builder),
      isDeleted: [this.isDeleted],
      coreTeam: this.createUserInfoArray(this.coreTeam || [], builder),
      type: [this.type],
      navLinksTitle: [this.navLinksTitle],
      koUsers: this.createUserInfoArray(this.koUsers, builder),
      isPrivate: [this.isPrivate]
    });
  }

  createStickyPostArray(list: StickyPost[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(sticky => {
      results.push(sticky.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createHastagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }

  createPostArray(list: Post[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(p => {
      results.push(p.toFormGroup(builder));
    });

    return results;
  }

  createNavLinkArray(list: NavLink[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createConversationArray(list: Conversation[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(c => {
      results.push(c.toFormGroup(builder));
    });

    return results;
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
}

export class StickyPost {
  public id: string;
  public stickyPostId: string;
  public networkId: string;
  public plainText: string;
  public order: number;
  public created: Date;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.stickyPostId = initialData.stickyPostId;
      this.networkId = initialData.networkId;
      this.plainText = initialData.plainText;
      this.order = initialData.order;
      this.created = initialData.created;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      stickyPostId: [this.stickyPostId],
      networkId: [this.networkId],
      plainText: [this.plainText],
      order: [this.order],
      created: [this.created]
    });
  }
}

export class NavLink {
  public imageUrl: string;
  public description: string;
  public link: string;
  public title: string;
  public id: string;
  public children: NavLink[];
  public attachments: IAttachment[];

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.imageUrl = initialData.imageUrl;
      this.description = initialData.description;
      this.link = initialData.link;
      this.title = initialData.title;
      this.children = initialData.children;
      this.attachments = initialData.attachments;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      imageUrl: [this.imageUrl],
      description: [this.description],
      link: [this.link],
      title: [this.title]
    });
  }
}

export class UserBadge {
  public followedUsers: UserInfo[];
  public followedHashtags: HashtagInfo[];
  public followedNetworks: NetworkInfo[];
  public followedChallenges: ChallengeInfo[];
  public followedIdeas: IdeaInfo[];
  public followedKnowledgeNugget: IkInfo[];
  public followedSuccessStory: SuccessStoryInfo[];
  public followedWebinars: FollowedWebinar[];
  public followerCount: number;
  public followedCount: number;
  public rootPostCount: number;
  public replyCount: number;
  public successStoriesCount: number;

  public webinarCount: number;
  public ideaCount: number;
  public knowledgeNuggetCount: number;
  public challengeCount: number;

  public followers: UserInfo[];

  constructor(initialData?: any) {
    if (initialData) {
      this.followedUsers = initialData.followedUsers
        ? initialData.followedUsers.map(u => new UserInfo(u))
        : [];
      this.followers = initialData.followers
        ? initialData.followers.map(u => new UserInfo(u))
        : [];
      this.followedHashtags = initialData.followedHashtags
        ? initialData.followedHashtags.map(h => new HashtagInfo(h))
        : [];
      this.followedChallenges = initialData.followedChallenges
        ? initialData.followedChallenges.map(h => new ChallengeInfo(h))
        : [];
      this.followedIdeas = initialData.followedIdeas
        ? initialData.followedIdeas.map(h => new IdeaInfo(h))
        : [];
      this.followedKnowledgeNugget = initialData.followedKnowledgeNugget
        ? initialData.followedKnowledgeNugget.map(h => new IkInfo(h))
        : [];
      this.followedSuccessStory = initialData.followedSuccessStory
        ? initialData.followedSuccessStory.map(h => new SuccessStoryInfo(h))
        : [];
      this.followedWebinars = initialData.followedWebinars
        ? initialData.followedWebinars.map(w => new FollowedWebinar(w))
        : [];
      this.followerCount = initialData.followerCount;
      this.followedCount = initialData.followedCount;
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
      this.webinarCount = initialData.webinarCount;
      this.ideaCount = initialData.ideaCount;
      this.knowledgeNuggetCount = initialData.knowledgeNuggetCount;
      this.challengeCount = initialData.challengeCount;
      this.followedNetworks = initialData.followedNetworks
        ? initialData.followedNetworks.map(n => new NetworkInfo(n))
        : [];
      this.successStoriesCount = initialData.successStoriesCount || 0;
    }
  }

  createSuccessStoryInfoArray(list: SuccessStoryInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }
  createIdeaInfoArray(list: IdeaInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }
  createIkInfoArray(list: IkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }
  createChallengeInfoArray(list: ChallengeInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class Activity {
  public type: ActivityType;
  public date: Date;
  public post: Post;
  public followedUser: UserInfo;
  public followedTag: HashtagInfo;
  public followedNetwork: NetworkInfo;
  public postUsers: UserInfo[];
  public postHashtags: HashtagInfo[];
  public user: UserInfo;
  public ik: IkInfo;
  public conversation: Conversation;

  constructor(initialData?: any) {
    if (initialData) {
      this.type = initialData.type;
      this.date = initialData.date;
      this.post = new Post(initialData.post);
      this.followedUser = new UserInfo(initialData.followedUser);
      this.followedTag = new HashtagInfo(initialData.followedTag);
      this.ik = new IkInfo(initialData.ik);
      (this.followedNetwork = new NetworkInfo(initialData.followedNetwork)),
        (this.user = new UserInfo(initialData.user));
      this.postUsers = initialData.postUsers
        ? initialData.postUsers.map(u => new UserInfo(u))
        : [];
      this.postHashtags = initialData.postHashtags
        ? initialData.postHashtags.map(h => new HashtagInfo(h))
        : [];
    }
  }
  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      type: [this.type],
      date: [this.date],
      post: this.post.toFormGroup(builder),
      followedUser: this.followedUser.toFormGroup(builder),
      followedTag: this.followedTag.toFormGroup(builder),
      followedNetwork: this.followedNetwork.toFormGroup(builder),
      promoteIk: this.ik.toFormGroup(builder),
      user: this.user.toFormGroup(builder),
      postUsers: this.createUserInfoArray(this.postUsers, builder),
      postHashtags: this.createHashtagInfoArray(this.postHashtags, builder)
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class AudienceInfo {
  public postId: string;
  public hashtagId: string;
  public created: Date;
  public createdBy: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.postId = initialData.postId;
      this.hashtagId = initialData.hashtagId;
      this.created = initialData.created;
      this.createdBy = initialData.createdBy;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      postId: [this.postId],
      hashtagId: [this.hashtagId],
      created: [this.created],
      createdBy: [this.createdBy]
    });
  }
}

export class ImportDataIK {
  additionalInformation: string;
  otherInvolvedPeople: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.additionalInformation = initialData.additionalInformation;
      this.otherInvolvedPeople = initialData.otherInvolvedPeople;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      additionalInformation: [this.additionalInformation],
      otherInvolvedPeople: [this.otherInvolvedPeople]
    });
  }
}

export class IKChoices {
  crucialGap: Choice[];
  deploymentCost: Choice;
  deploymentTime: Choice;
  contentDeliverer: Choice;
  availabilityTime: Choice;
  expectedRevenue: Choice;

  constructor(initialData?: any) {
    if (initialData) {
      this.crucialGap =
        initialData.crucialGap && initialData.crucialGap.length > 0
          ? initialData.crucialGap.map(el => new Choice(el))
          : null;
      this.deploymentCost = initialData.deploymentCost
        ? new Choice(initialData.deploymentCost)
        : null;
      this.deploymentTime = initialData.deploymentTime
        ? new Choice(initialData.deploymentTime)
        : null;
      this.contentDeliverer = initialData.contentDeliverer
        ? new Choice(initialData.contentDeliverer)
        : null;
      this.availabilityTime = initialData.availabilityTime
        ? new Choice(initialData.availabilityTime)
        : null;
      this.expectedRevenue = initialData.expectedRevenue
        ? new Choice(initialData.expectedRevenue)
        : null;
    }
  }
}

export class KnowledgeNuggetDiffusionRating{
  koUserId: string;
  rating: number;
}

export class KnowledgeNugget extends IKChoices {
  id: string;
  type = ImpactingKnowledgeType.KnowledgeNugget;
  hashtag: HashtagInfo;
  name: string;
  description: string;
  logoUrl: string;
  coverUrl: string;
  promotingNetworks: NetworkInfo[];
  involvedPeople: UserInfo[];
  relatedTags: HashtagInfo[];
  sourcePostId: string;
  businessImpact: Choice;
  impactingKnowledge: string;
  originalEvaluationChoices: string;
  attachments: IAttachment[];
  created: Date;
  createdBy: UserInfo;
  modified: Date;
  modifiedBy: UserInfo;
  isDeleted: boolean;
  deleted: Date;
  deletedBy: UserInfo;
  additionalInformation: string;
  otherInvolvedPeople: string;
  isImported: boolean;
  diffusionRatings: KnowledgeNuggetDiffusionRating[];

  constructor(initialData?: any) {
    super(initialData);
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.name = initialData.name;
      this.logoUrl = initialData.logoUrl;
      this.description = initialData.description;
      this.coverUrl = initialData.coverUrl;
      this.sourcePostId = initialData.sourcePostId;
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p))
        : [];
      this.involvedPeople = initialData.involvedPeople
        ? initialData.involvedPeople.map(p => new UserInfo(p))
        : [];
      this.relatedTags = initialData.relatedTags
        ? initialData.relatedTags.map(p => new HashtagInfo(p))
        : [];
      this.attachments = initialData.attachments;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.originalEvaluationChoices = initialData.originalEvaluationChoices;
      this.isDeleted = initialData.isDeleted;
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
      this.additionalInformation = initialData.additionalInformation;
      this.otherInvolvedPeople = initialData.otherInvolvedPeople;
      this.isImported = initialData.isImported;

      this.diffusionRatings = initialData.diffusionRatings;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: this.hashtag
        ? this.hashtag.toFormGroup(builder)
        : new HashtagInfo().toFormGroup(builder),
      name: [this.name],
      logoUrl: [this.logoUrl],
      description: [this.description],
      coverUrl: [this.coverUrl],
      sourcePostId: [this.sourcePostId],
      promotingNetworks: this.createNetworkInfoArray(
        this.promotingNetworks || [],
        builder
      ),
      involvedPeople: this.createUserInfoArray(
        this.involvedPeople || [],
        builder
      ),
      relatedTags: this.createHashtagInfoArray(this.relatedTags || [], builder),
      created: [this.created],
      createdBy: this.createdBy
        ? this.createdBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      modified: [this.modified],
      modifiedBy: this.modifiedBy
        ? this.modifiedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      isDeleted: [this.isDeleted],
      deleted: [this.deleted],
      deletedBy: this.deletedBy
        ? this.deletedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      businessImpact: this.businessImpact
        ? new FormControl(this.businessImpact)
        : null,
      crucialGap: this.crucialGap ? new FormControl(this.crucialGap) : null,
      deploymentCost: this.deploymentCost
        ? new FormControl(this.deploymentCost)
        : null,
      deploymentTime: this.deploymentTime
        ? new FormControl(this.deploymentTime)
        : null,
      contentDeliverer: this.contentDeliverer
        ? new FormControl(this.contentDeliverer)
        : null,
      availabilityTime: this.availabilityTime
        ? new FormControl(this.availabilityTime)
        : null,
      expectedRevenue: this.expectedRevenue
        ? new FormControl(this.expectedRevenue)
        : null,
      originalEvaluationChoices: [this.originalEvaluationChoices],
      additionalInformation: [this.additionalInformation],
      otherInvolvedPeople: [this.otherInvolvedPeople],
      isImported: [this.isImported],
      attachments: this.attachments
        ? new FormArray(this.attachments.map(a => new FormControl(a)))
        : []
    });
  }

  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class IkInfo {
  public id: string;
  public type = ImpactingKnowledgeType.KnowledgeNugget;
  public hashtag: HashtagInfo;
  public name: string;
  public description: string;
  public logoUrl: string;
  public coverUrl: string;
  public sourcePostId: string;
  public created: Date;
  public modified: Date;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = initialData.hashtag
        ? new HashtagInfo(initialData.hashtag)
        : new HashtagInfo();
      this.name = initialData.name;
      this.description = initialData.description;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.sourcePostId = initialData.sourcePostId;
      this.created = initialData.created;
      this.modified = initialData.modified;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: this.hashtag
        ? this.hashtag.toFormGroup(builder)
        : new HashtagInfo().toFormGroup(builder),
      name: [this.name],
      logoUrl: [this.logoUrl],
      description: [this.description],
      coverUrl: [this.coverUrl],
      sourcePostId: [this.sourcePostId],
      created: [this.created],
      modified: [this.modified]
    });
  }
}

export class Challenge {
  id: string;
  type = ImpactingKnowledgeType.Challenge;
  hashtag: HashtagInfo;
  name: string;
  description: string;
  logoUrl: string;
  coverUrl: string;
  promotingNetworks: NetworkInfo[];
  involvedPeople: UserInfo[];
  relatedTags: HashtagInfo[];
  // sourcePostId: string;
  businessImpact: Choice;
  // businessImpact: string;
  originalEvaluationChoices: string;
  startDate: Date;
  endDate: Date;
  daysLeft: number;
  evaluationCommitteeDate: Date;
  evaluationCommittee: UserInfo[];
  result: string;
  status: Lookup<number, string>;
  created: Date;
  createdBy: UserInfo;
  modified: Date;
  modifiedBy: UserInfo;
  isDeleted: boolean;
  deleted: Date;
  deletedBy: UserInfo;
  attachments: IAttachment[];
  bestIdeas: IdeaInfo[];
  additionalInformation: string;
  otherInvolvedPeople: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.name = initialData.name;
      this.logoUrl = initialData.logoUrl;
      this.description = initialData.description;
      this.coverUrl = initialData.coverUrl;
      // this.sourcePostId = initialData.sourcePostId;
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p))
        : [];
      this.involvedPeople = initialData.involvedPeople
        ? initialData.involvedPeople.map(p => new UserInfo(p))
        : [];
      this.relatedTags = initialData.relatedTags
        ? initialData.relatedTags.map(p => new HashtagInfo(p))
        : [];
      this.startDate = Utilities.adjustDateMilanToUtc(initialData.startDate);
      this.endDate = Utilities.adjustDateMilanToUtc(initialData.endDate);
      this.daysLeft = this.endDate
        ? Math.round(
            (new Date(this.endDate).getTime() - new Date().getTime()) /
              (1000 * 60 * 60 * 24)
          )
        : null;
      this.evaluationCommitteeDate = initialData.evaluationCommitteeDate;
      this.evaluationCommittee = initialData.evaluationCommittee
        ? initialData.evaluationCommittee.map(p => new UserInfo(p))
        : [];
      this.result = initialData.result;
      this.status = initialData.status;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.originalEvaluationChoices = initialData.originalEvaluationChoices;
      this.isDeleted = initialData.isDeleted;
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
      this.attachments = initialData.attachments;
      this.bestIdeas = initialData.bestIdeas
        ? initialData.bestIdeas.map(p => new IdeaInfo(p))
        : [];
      this.additionalInformation = initialData.additionalInformation;
      this.otherInvolvedPeople = initialData.otherInvolvedPeople;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: this.hashtag
        ? this.hashtag.toFormGroup(builder)
        : new HashtagInfo().toFormGroup(builder),
      name: [this.name],
      logoUrl: [this.logoUrl],
      description: [this.description],
      coverUrl: [this.coverUrl],
      // sourcePostId: [this.sourcePostId],
      promotingNetworks: this.createNetworkInfoArray(
        this.promotingNetworks || [],
        builder
      ),
      involvedPeople: this.createUserInfoArray(
        this.involvedPeople || [],
        builder
      ),
      relatedTags: this.createHashtagInfoArray(this.relatedTags || [], builder),
      startDate: [this.startDate],
      endDate: [this.endDate],
      evaluationCommitteeDate: [this.evaluationCommitteeDate],
      evaluationCommittee: this.createUserInfoArray(
        this.evaluationCommittee || [],
        builder
      ),
      result: [this.result],
      status: [this.status],
      created: [this.created],
      createdBy: this.createdBy.toFormGroup(builder),
      modified: [this.modified],
      modifiedBy: this.modifiedBy
        ? this.modifiedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      isDeleted: [this.isDeleted],
      deleted: [this.deleted],
      deletedBy: this.deletedBy
        ? this.deletedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      businessImpact: this.businessImpact
        ? new FormControl(this.businessImpact)
        : null,
      originalEvaluationChoices: [this.originalEvaluationChoices],
      bestIdeas: this.createIdeaInfoArray(this.bestIdeas || [], builder),
      additionalInformation: [this.additionalInformation],
      otherInvolvedPeople: [this.otherInvolvedPeople],
      attachments: this.attachments
        ? new FormArray(this.attachments.map(a => new FormControl(a)))
        : []
    });
  }

  createIdeaInfoArray(list: IdeaInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class ChallengeInfo {
  public id: string;
  public type = ImpactingKnowledgeType.Challenge;
  public hashtag: HashtagInfo;
  public name: string;
  public description: string;
  public logoUrl: string;
  public coverUrl: string;
  public created: Date;
  public modified: Date;
  public businessImpact: Choice;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = initialData.hashtag;
      this.name = initialData.name;
      this.description = initialData.description;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.created = initialData.created;
      this.modified = initialData.modified;
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: [this.hashtag],
      name: [this.name],
      description: [this.description],
      logoUrl: [this.logoUrl],
      coverUrl: [this.coverUrl],
      created: [this.created],
      modified: [this.modified],
      businessImpact: this.businessImpact
        ? new FormControl(this.businessImpact)
        : null
    });
  }
}

export class Idea extends IKChoices {
  id: string;
  hashtag: HashtagInfo;
  type = ImpactingKnowledgeType.Idea;
  name: string;
  description: string;
  logoUrl: string;
  coverUrl: string;
  promotingNetworks: NetworkInfo[];
  involvedPeople: UserInfo[];
  relatedTags: HashtagInfo[];
  sourcePostId: string;
  businessImpact: Choice;
  challenges: ChallengeInfo[];
  buildingBlocks: Post[];
  // businessImpact: string;
  created: Date;
  createdBy: UserInfo;
  modified: Date;
  modifiedBy: UserInfo;
  sourcePostAuthor: UserInfo;
  attachments: IAttachment[];
  originalEvaluationChoices: string;
  additionalInformation: string;
  otherInvolvedPeople: string;
  isImported: boolean;

  isDeleted: boolean;

  constructor(initialData?: any) {
    super(initialData);
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = initialData.hashtag
        ? new HashtagInfo(initialData.hashtag)
        : new HashtagInfo();
      this.name = initialData.name;
      this.description = initialData.description;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.sourcePostId = initialData.sourcePostId;
      this.attachments = initialData.attachments;
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p))
        : [];
      this.involvedPeople = initialData.involvedPeople
        ? initialData.involvedPeople.map(p => new UserInfo(p))
        : [];
      this.relatedTags = initialData.relatedTags
        ? initialData.relatedTags.map(p => new HashtagInfo(p))
        : [];
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.challenges = initialData.challenges
        ? initialData.challenges.map(p => new ChallengeInfo(p))
        : [];
      this.buildingBlocks = initialData.buildingBlocks
        ? initialData.buildingBlocks.map(p => new Post(p))
        : [];
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.sourcePostAuthor = new UserInfo(initialData.sourcePostAuthor);
      this.originalEvaluationChoices = initialData.originalEvaluationChoices;
      this.additionalInformation = initialData.additionalInformation;
      this.otherInvolvedPeople = initialData.otherInvolvedPeople;
      this.isImported = initialData.isImported;
      this.isDeleted = initialData.isDeleted;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: this.hashtag
        ? this.hashtag.toFormGroup(builder)
        : new HashtagInfo().toFormGroup(builder),
      name: [this.name],
      logoUrl: [this.logoUrl],
      description: [this.description],
      coverUrl: [this.coverUrl],
      sourcePostId: [this.sourcePostId],
      promotingNetworks: this.createNetworkInfoArray(
        this.promotingNetworks || [],
        builder
      ),
      involvedPeople: this.createUserInfoArray(
        this.involvedPeople || [],
        builder
      ),
      relatedTags: this.createHashtagInfoArray(this.relatedTags || [], builder),
      challenges: this.createChallengeArray(this.challenges || [], builder),
      buildingBlocks: this.createPostArray(this.buildingBlocks || [], builder),
      created: [this.created],
      createdBy: this.createdBy.toFormGroup(builder),
      modified: [this.modified],
      modifiedBy: this.modifiedBy
        ? this.modifiedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      businessImpact: this.businessImpact
        ? new FormControl(this.businessImpact)
        : null,
      sourcePostAuthor: this.sourcePostAuthor
        ? this.sourcePostAuthor.toFormGroup(builder)
        : null,
      crucialGap: this.crucialGap ? new FormControl(this.crucialGap) : null,
      deploymentCost: this.deploymentCost
        ? new FormControl(this.deploymentCost)
        : null,
      deploymentTime: this.deploymentTime
        ? new FormControl(this.deploymentTime)
        : null,
      contentDeliverer: this.contentDeliverer
        ? new FormControl(this.contentDeliverer)
        : null,
      availabilityTime: this.availabilityTime
        ? new FormControl(this.availabilityTime)
        : null,
      expectedRevenue: this.expectedRevenue
        ? new FormControl(this.expectedRevenue)
        : null,
      originalEvaluationChoices: [this.originalEvaluationChoices],
      additionalInformation: [this.additionalInformation],
      otherInvolvedPeople: [this.otherInvolvedPeople],
      isImported: [this.isImported],
      isDeleted: [this.isDeleted],
      attachments: this.attachments
        ? new FormArray(this.attachments.map(a => new FormControl(a)))
        : []
    });
  }

  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createChallengeArray(list: ChallengeInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createPostArray(list: Post[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class IdeaInfo {
  id: string;
  type = ImpactingKnowledgeType.Idea;
  hashtag: HashtagInfo;
  name: string;
  description: string;
  logoUrl: string;
  coverUrl: string;
  sourcePostId: string;
  businessImpact: Choice;
  created: Date;
  modified: Date;
  sourcePostAuthor: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = initialData.hashtag
        ? new HashtagInfo(initialData.hashtag)
        : new HashtagInfo();
      this.name = initialData.name;
      this.description = initialData.description;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.sourcePostId = initialData.sourcePostId;
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.created = initialData.created;
      this.modified = initialData.modified;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: this.hashtag
        ? this.hashtag.toFormGroup(builder)
        : new HashtagInfo().toFormGroup(builder),
      name: [this.name],
      logoUrl: [this.logoUrl],
      description: [this.description],
      coverUrl: [this.coverUrl],
      sourcePostId: [this.sourcePostId],
      created: [this.created],
      modified: [this.modified],
      businessImpact: this.businessImpact
        ? new FormControl(this.businessImpact)
        : null
    });
  }
}

export class SuccessStory extends IKChoices { //TODO: aggiungere il parametro professional experience nel DTO 
  public id: string;
  public type: ImpactingKnowledgeType;
  public hashtag: HashtagInfo;
  public name: string;
  public description: string;
  public focus: string;
  public logoUrl: string;
  public coverUrl: string;
  public promotingNetworks: NetworkInfo[];
  public involvedPeople: UserInfo[];
  public relatedTags: HashtagInfo[];
  public businessImpact: Choice;
  public originalEvaluationChoices: string[];
  public context: string;
  public kmsInteraction: string;
  public result: string;
  public benefits: string;
  public status: SuccessStoryStatus;
  public created: Date;
  public createdBy: UserInfo;
  public modified: Date;
  public modifiedBy: UserInfo;
  public isDeleted: boolean;
  public deleted: Date;
  public deletedBy: UserInfo;
  public relatedIKs: IkInfo[];
  public attachments: IAttachment[];
  public additionalInformation: string;
  public otherInvolvedPeople: string;
  public isImported: boolean;
  public professionalExperience: boolean;
  

  constructor(initialData?: any) {
    super(initialData);
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.name = initialData.name;
      this.description = initialData.description;
      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.promotingNetworks = initialData.promotingNetworks
        ? initialData.promotingNetworks.map(p => new NetworkInfo(p))
        : [];
      this.involvedPeople = initialData.involvedPeople
        ? initialData.involvedPeople.map(p => new UserInfo(p))
        : [];
      this.relatedTags = initialData.relatedTags
        ? initialData.relatedTags.map(p => new HashtagInfo(p))
        : [];
      this.businessImpact = initialData.businessImpact
        ? new Choice(initialData.businessImpact)
        : null;
      this.originalEvaluationChoices = initialData.originalEvaluationChoices
        ? initialData.originalEvaluationChoices
        : [];
      this.context = initialData.context;
      this.kmsInteraction = initialData.kmsInteraction;
      this.result = initialData.result;
      this.benefits = initialData.benefits;
      this.status = initialData.status;
      this.created = initialData.created;
      this.createdBy = new UserInfo(initialData.createdBy);
      this.modified = initialData.modified;
      this.modifiedBy = new UserInfo(initialData.modifiedBy);
      this.isDeleted = initialData.isDeleted;
      this.deleted = initialData.deleted;
      this.deletedBy = new UserInfo(initialData.deletedBy);
      this.relatedIKs = initialData.relatedIKs
        ? initialData.relatedIKs.map(p => new IkInfo(p))
        : [];
      this.attachments = initialData.attachments;
      this.additionalInformation = initialData.additionalInformation;
      this.otherInvolvedPeople = initialData.otherInvolvedPeople;
      this.isImported = initialData.isImported;
      this.professionalExperience = initialData.professionalExperience;
    }
  }
  toFormGroup(builder: FormBuilder) {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: this.hashtag
        ? this.hashtag.toFormGroup(builder)
        : new HashtagInfo().toFormGroup(builder),
      name: [this.name],
      description: [this.description],
      logoUrl: [this.logoUrl],
      coverUrl: [this.coverUrl],
      promotingNetworks: this.createNetworkInfoArray(
        this.promotingNetworks || [],
        builder
      ),
      involvedPeople: this.createUserInfoArray(
        this.involvedPeople || [],
        builder
      ),
      relatedTags: this.createHashtagInfoArray(this.relatedTags || [], builder),
      businessImpact: this.businessImpact
        ? new FormControl(this.businessImpact)
        : null,
      originalEvaluationChoices: this.createStringArray(
        this.originalEvaluationChoices || [],
        builder
      ),
      context: [this.context],
      kmsInteraction: [this.kmsInteraction],
      result: [this.result],
      benefits: [this.benefits],
      status: [this.status],
      created: [this.created],
      createdBy: this.createdBy
        ? this.createdBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      modified: [this.modified],
      modifiedBy: this.modifiedBy
        ? this.modifiedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      isDeleted: [this.isDeleted],
      deleted: [this.deleted],
      deletedBy: this.deletedBy
        ? this.deletedBy.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      relatedIKs: this.createIkInfoArray(this.relatedIKs || [], builder),
      crucialGap: this.crucialGap ? new FormControl(this.crucialGap) : null,
      deploymentCost: this.deploymentCost
        ? new FormControl(this.deploymentCost)
        : null,
      deploymentTime: this.deploymentTime
        ? new FormControl(this.deploymentTime)
        : null,
      contentDeliverer: this.contentDeliverer
        ? new FormControl(this.contentDeliverer)
        : null,
      availabilityTime: this.availabilityTime
        ? new FormControl(this.availabilityTime)
        : null,
      expectedRevenue: this.expectedRevenue
        ? new FormControl(this.expectedRevenue)
        : null,
      additionalInformation: [this.additionalInformation],
      otherInvolvedPeople: [this.otherInvolvedPeople],
      isImported: [this.isImported],
      professionalExperience: this.professionalExperience
        ? new FormControl(this.professionalExperience)
        : null,
      attachments: this.attachments
        ? new FormArray(this.attachments.map(a => new FormControl(a)))
        : []
    });
  }

  createIkInfoArray(list: IkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }
  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }
  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(n => {
      results.push(n.toFormGroup(builder));
    });

    return results;
  }

  createHashtagInfoArray(list: HashtagInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
}

export class FollowedWebinar {
  public id: string;
  public type: ImpactingKnowledgeType;
  public hashtag: HashtagInfo;
  public name: string;
  public description: string;
  public logoUrl: string;
  public coverUrl: string;
  public sourcePostId: string;
  public created: Date;
  public modified: Date;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.name = initialData.name;
      this.description = initialData.description;

      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.sourcePostId = initialData.sourcePostId;
      this.created = initialData.created;
      this.modified = initialData.modified;
    }
  }
}

export class SuccessStoryInfo {
  public id: string;
  public type: ImpactingKnowledgeType;
  public hashtag: HashtagInfo;
  public name: string;
  public description: string;
  public logoUrl: string;
  public coverUrl: string;
  public sourcePostId: string;
  public created: Date;
  public modified: Date;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.type = initialData.type;
      this.hashtag = new HashtagInfo(initialData.hashtag);
      this.name = initialData.name;
      this.description = initialData.description;

      this.logoUrl = initialData.logoUrl;
      this.coverUrl = initialData.coverUrl;
      this.sourcePostId = initialData.sourcePostId;
      this.created = initialData.created;
      this.modified = initialData.modified;
    }
  }
  toFormGroup(builder: FormBuilder) {
    return builder.group({
      id: [this.id],
      type: [this.type],
      hashtag: this.hashtag
        ? this.hashtag.toFormGroup(builder)
        : new HashtagInfo().toFormGroup(builder),
      name: [this.name],
      description: [this.description],
      logoUrl: [this.logoUrl],
      coverUrl: [this.coverUrl],
      sourcePostId: [this.sourcePostId],
      created: [this.created],
      modified: [this.modified]
    });
  }
}

export class KnowledgeNuggetBadge {
  followers: UserInfo[];
  rootPostCount: number;
  replyCount: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.followers = initialData.followers;
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      followers: this.createUserInfoArray(this.followers, builder),
      rootPostCount: [this.rootPostCount],
      replyCount: [this.replyCount]
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class IdeaBadge {
  followers: UserInfo[];
  rootPostCount: number;
  replyCount: number;
  challenges: ChallengeInfo[];

  constructor(initialData?: any) {
    if (initialData) {
      this.followers = initialData.followers;
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
      this.challenges = initialData.challenges
        ? initialData.challenges.map(c => new ChallengeInfo(c))
        : [];
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      followers: this.createUserInfoArray(this.followers, builder),
      rootPostCount: [this.rootPostCount],
      replyCount: [this.replyCount],
      challenges: this.createChallengeInfoArray(this.challenges, builder)
    });
  }

  createChallengeInfoArray(list: ChallengeInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class ChallengeBadge {
  followers: UserInfo[];
  rootPostCount: number;
  replyCount: number;
  ideas: IdeaInfo[];
  participants: UserInfo[];

  constructor(initialData?: any) {
    if (initialData) {
      this.followers = initialData.followers;
      this.participants = initialData.participants
        ? initialData.participants.map(u => new UserInfo(u))
        : [];
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
      this.ideas = initialData.ideas
        ? initialData.ideas.map(u => new IdeaInfo(u))
        : [];
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      followers: this.createUserInfoArray(this.followers, builder),
      rootPostCount: [this.rootPostCount],
      replyCount: [this.replyCount],
      ideas: this.createIdeaInfoArray(this.ideas, builder),
      participants: this.createUserInfoArray(this.participants, builder)
    });
  }

  createUserInfoArray(list: UserInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }

  createIdeaInfoArray(list: IdeaInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export class Evaluation {
  public id: string;
  public objectId: string;
  public user: UserInfo;
  public indicators: Choice[];
  public type: UserType;
  public objectType: EvaluationObjectType;
  public created: Date;
  public modified: Date;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.objectId = initialData.objectId;
      this.user = initialData.user ? new UserInfo(initialData.user) : null;
      this.indicators = initialData.indicators
        ? initialData.indicators.map(i => new Choice(i))
        : [];
      this.type = initialData.type;
      this.objectType = initialData.objectType;
      this.created = initialData.created;
      this.modified = initialData.modified;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      objectId: [this.objectId],
      user: this.user
        ? this.user.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      indicators: this.createChoiceArray(this.indicators || [], builder),
      type: [this.type],
      objectType: [this.objectType],
      created: [this.created],
      modified: [this.modified]
    });
  }

  createChoiceArray(list: Choice[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class Participation extends Evaluation {
  public participants: number;

  constructor(initialData?: any) {
    super(initialData);
    if (initialData) {
      this.participants = initialData.participants;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      objectId: [this.objectId],
      user: this.user
        ? this.user.toFormGroup(builder)
        : new UserInfo().toFormGroup(builder),
      indicators: this.createChoiceArray(this.indicators || [], builder),
      type: [this.type],
      objectType: [this.objectType],
      created: [this.created],
      modified: [this.modified],
      participants: [this.participants]
    });
  }
}

export class ChoiceDefinition {
  public fieldId: string;
  public fieldTitle: string;
  public fieldCategory: number;
  public fieldTooltip: string;
  public fieldType: number;
  public choices: Axis[];
  public weight: number;
  public order: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.fieldId = initialData.fieldId;
      this.fieldTitle = initialData.fieldTitle;
      (this.fieldTooltip = initialData.fieldTooltip),
        (this.fieldCategory = initialData.fieldCategory);
      this.fieldType = initialData.fieldType;
      this.choices = initialData.choices
        ? initialData.choices.map(c => new Axis(c))
        : [];
      this.weight = initialData.weight;
      this.order = initialData.order;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      fieldId: [this.fieldId],
      fieldTitle: [this.fieldTitle],
      fieldTooltip: [this.fieldTooltip],
      fieldCategory: [this.fieldCategory],
      fieldType: [this.fieldType],
      choices: this.createAxisArray(this.choices, builder),
      weight: [this.weight],
      order: [this.order]
    });
  }

  createAxisArray(list: Axis[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class ChoiceField {
  public fieldId: string;
  public fieldTitle: string;
  public fieldCategory: number;
  public fieldType: number;
  public choices: BasicLookup[];
  public weight: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.fieldId = initialData.fieldId;
      this.fieldTitle = initialData.fieldTitle;
      this.fieldCategory = initialData.fieldCategory;
      this.fieldType = initialData.fieldType;
      this.choices = initialData.choices
        ? initialData.choices.map(c => new BasicLookup(c))
        : [];
      this.weight = initialData.weight;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      fieldId: [this.fieldId],
      fieldTitle: [this.fieldTitle],
      fieldCategory: [this.fieldCategory],
      fieldType: [this.fieldType],
      choices: this.createBasicLookupArray(this.choices || [], builder),
      weight: [this.weight]
    });
  }

  createBasicLookupArray(list: BasicLookup[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(h => {
      results.push(h.toFormGroup(builder));
    });

    return results;
  }
}

export class Choice {
  public fieldId: string;
  public fieldLabel: string;
  public fieldTooltip: string;
  public choiceId: number;
  public choiceLabel: string;
  public choiceValue: number;
  public order: number;
  public fieldCategory: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.fieldId = initialData.fieldId;
      this.choiceId = initialData.choiceId;
      this.fieldLabel = initialData.fieldLabel;
      this.fieldTooltip = initialData.fieldTooltip;
      this.choiceLabel = initialData.choiceLabel;
      this.choiceValue = initialData.choiceValue;
      this.order = initialData.order;
      this.fieldCategory = initialData.fieldCategory;
    }
  }

  toFormGroup(builder: FormBuilder) {
    return builder.group({
      fieldId: [this.fieldId],
      choiceId: [this.choiceId],
      fieldLabel: [this.fieldLabel],
      fieldTooltip: [this.fieldTooltip],
      choiceLabel: [this.choiceLabel],
      choiceValue: [this.choiceValue],
      order: [this.order],
      fieldCategory: [this.fieldCategory]
    });
  }
}

export class Lookup<K, V> {
  public key: K;
  public value: V;
}

export class BasicLookup extends Lookup<number, string> {
  constructor(initialData?: any) {
    super();
    if (initialData) {
      this.key = initialData.key;
      this.value = initialData.value;
    }
  }

  toFormGroup(builder: FormBuilder) {
    return builder.group({
      Key: [this.key],
      value: [this.value]
    });
  }
}

export class LookUpString {
  key: string;
  value: any;

  constructor(key: string, value: any) {
    this.key = key;
    this.value = value;
  }
}

export class KeyPhrase {
  language: string;
  id: string;
  text: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.language = initialData.language;
      this.id = initialData.id;
      this.text = initialData.text;
    }
  }
}

export class KeyPhraseResult {
  public documents: KeyPhraseBatchResultItem[];

  public errors: ErrorRecord[];

  public keyPhrases: string[];

  public id: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.documents = initialData.documents
        ? initialData.documents.map(d => new KeyPhraseBatchResultItem(d))
        : [];
      this.errors = initialData.errors
        ? initialData.errors.map(e => new ErrorRecord(e))
        : [];
      this.keyPhrases = initialData.keyPhrases ? initialData.keyPhrases : [];
      this.id = initialData.id;
    }
  }
}

export class KeyPhraseBatchResultItem {
  public keyPhrases: string[];
  public id: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.keyPhrases = initialData.keyPhrases;
      this.id = initialData.id;
    }
  }
}

export class ErrorRecord {
  public id: string;
  public message: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.message = initialData.message;
      this.id = initialData.id;
    }
  }
}

export class Axis {
  public key: number;
  public value: number;
  public label: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.key = initialData.key;
      this.value = initialData.value;
      this.label = initialData.label;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      key: [this.key],
      value: [this.value],
      label: [this.label]
    });
  }
}

export class Role {
  public userId: string;
  public upn: string;
  public admin: boolean;
  public kmTeam: boolean;
  public champion: boolean;
  public webinarTeam: boolean;
  public leleFocalPoint: boolean;
  public copFacilitatorScopes: string[];
  public copFacilitatorScopeNames: string[];
  public copCoFacilitatorScopes: string[];
  public copCoFacilitatorScopeNames: string[];
  public focalPointScopeNames: string[];
  public koScopes: string[];
  public koScopeNames: string[];
  public buFocalPointScopes: string[];
  public buFocalPointScopesNames: string[];
  public buReferentScopes: string[];
  public buReferentScopeNames: string[];
  public specialTagReferentScopeNames: string[];
  public specialTagReferentScopes: string[];
  public specialTagCoreTeamScopes: string[];
  public specialTagCoreTeamScopeNames: string[];
  public externalMemberScopes: string[];
  public externalReferentScopes: string[];
  public externalReferentScopeNames: string[];
  public isKOEligible: boolean;

  constructor(initialData?: any) {
    if (initialData) {
      this.userId = initialData.userId;
      this.upn = initialData.upn;
      if (!initialData.admin) {
        this.admin = false;
      } else {
        this.admin = initialData.admin;
      }
      if (!initialData.kmTeam) {
        this.kmTeam = false;
      } else {
        this.kmTeam = initialData.kmTeam;
      }
      this.champion = initialData.champion;
      if (!initialData.webinarTeam) {
        this.webinarTeam = false;
      } else {
        this.webinarTeam = initialData.webinarTeam;
      }
      if (!initialData.leleFocalPoint) {
        this.leleFocalPoint = false;
      } else {
        this.leleFocalPoint = initialData.leleFocalPoint;
      }
      this.copFacilitatorScopes = initialData.copFacilitatorScopes
        ? initialData.copFacilitatorScopes
        : [];
      this.copCoFacilitatorScopes = initialData.copCoFacilitatorScopes
        ? initialData.copCoFacilitatorScopes
        : [];
      this.koScopes = initialData.koScopes ? initialData.koScopes : [];
      this.focalPointScopeNames = initialData.focalPointScopeNames
        ? initialData.focalPointScopeNames
        : [];
      this.koScopeNames = initialData.koScopeNames
        ? initialData.koScopeNames
        : [];
      this.copCoFacilitatorScopeNames = initialData.copCoFacilitatorScopeNames
        ? initialData.copCoFacilitatorScopeNames
        : [];
      this.copFacilitatorScopeNames = initialData.copFacilitatorScopeNames
        ? initialData.copFacilitatorScopeNames
        : [];

      this.buFocalPointScopes = initialData.buFocalPointScopes
        ? initialData.buFocalPointScopes
        : [];
      this.buFocalPointScopesNames = initialData.buFocalPointScopesNames
        ? initialData.buFocalPointScopesNames
        : [];

      this.buReferentScopes = initialData.buReferentScopes
        ? initialData.buReferentScopes
        : [];
      this.buReferentScopeNames = initialData.buReferentScopeNames
        ? initialData.buReferentScopeNames
        : [];

      this.specialTagReferentScopeNames = initialData.specialTagReferentScopeNames
        ? initialData.specialTagReferentScopeNames
        : [];
      this.specialTagReferentScopes = initialData.specialTagReferentScopes
        ? initialData.specialTagReferentScopes
        : [];
      if (!initialData.isKOEligible) {
        this.isKOEligible = false;
      } else {
        this.isKOEligible = initialData.isKOEligible;
      }
      this.specialTagCoreTeamScopes = initialData.specialTagCoreTeamScopes
        ? initialData.specialTagCoreTeamScopes
        : [];
      this.specialTagCoreTeamScopeNames = initialData.specialTagCoreTeamScopeNames
        ? initialData.specialTagCoreTeamScopeNames
        : [];

      this.externalMemberScopes = initialData.externalMemberScopes
        ? initialData.externalMemberScopes
        : [];
      this.externalReferentScopes = initialData.externalReferentScopes
        ? initialData.externalReferentScopes
        : [];
      this.externalReferentScopeNames = initialData.externalReferentScopeNames
        ? initialData.externalReferentScopeNames
        : [];
    }
  }

  SpecialTagReferentScopesMapping() {
    return this.specialTagReferentScopes.map(
      x => `${RoleNameVariable.Referent}_${x}`
    );
  }

  SpecialTagCoreTeamScopeNamesMapping() {
    return this.specialTagCoreTeamScopeNames.map(
      x => `${RoleNameVariable.CoreTeam}_${x}`
    );
  }

  CoFacilitatorMapping() {
    return this.copCoFacilitatorScopes.map(
      x => `${RoleNameVariable.CoFacilitator}_${x}`
    );
  }
  FacilitatorMapping() {
    return this.copFacilitatorScopes.map(
      x => `${RoleNameVariable.Facilitator}_${x}`
    );
  }

  FocalPointMapping() {
    return this.buFocalPointScopesNames.map(
      x => `${RoleNameVariable.FocalPoint}_${x}`
    );
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      userId: [this.userId],
      upn: [this.upn],
      admin: [this.admin],
      kmTeam: [this.kmTeam],
      champion: [this.champion],
      webinarTeam: [this.webinarTeam],
      copFacilitatorScopes: this.createStringArray(
        this.copFacilitatorScopes,
        builder
      ),
      copCoFacilitatorScopes: this.createStringArray(
        this.copCoFacilitatorScopes,
        builder
      ),
      koScopes: this.createStringArray(this.koScopes, builder),
      focalPointScopeNames: this.createStringArray(
        this.focalPointScopeNames,
        builder
      ),
      koScopeNames: this.createStringArray(this.koScopeNames, builder),
      copCoFacilitatorScopeNames: this.createStringArray(
        this.copCoFacilitatorScopeNames,
        builder
      ),
      copFacilitatorScopeNames: this.createStringArray(
        this.copFacilitatorScopeNames,
        builder
      ),

      buFocalPointScopes: this.createStringArray(
        this.buFocalPointScopes,
        builder
      ),
      buReferentScopes: this.createStringArray(this.buReferentScopes, builder),
      buReferentScopeNames: this.createStringArray(
        this.buReferentScopeNames,
        builder
      ),
      buFocalPointScopesNames: this.createStringArray(
        this.buFocalPointScopesNames,
        builder
      ),
      specialTagReferentScopeNames: this.createStringArray(
        this.specialTagReferentScopeNames,
        builder
      ),
      specialTagReferentScopes: this.createStringArray(
        this.specialTagReferentScopes,
        builder
      ),
      isKOEligible: [this.isKOEligible],
      specialTagCoreTeamScopes: this.createStringArray(
        this.specialTagCoreTeamScopes,
        builder
      ),
      specialTagCoreTeamScopeNames: this.createStringArray(
        this.specialTagCoreTeamScopeNames,
        builder
      ),

      externalMemberScopes: this.createStringArray(
        this.externalMemberScopes,
        builder
      ),
      externalReferentScopes: this.createStringArray(
        this.externalReferentScopes,
        builder
      ),
      externalReferentScopeNames: this.createStringArray(
        this.externalReferentScopeNames,
        builder
      )
    });
  }

  createStringArray(list: string[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(s => {
      results.push(new FormControl(s));
    });

    return results;
  }
}

export class StrategicTagBadge {
  public hashtag: HashtagInfo;
  public followers: number;
  public rootPostCount: number;
  public replyCount: number;
  public webinars: number;
  public knowledgeNuggets: number;
  public challenges: number;
  public ideas: number;
  public successStories: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.challenges = initialData.challenges;
      this.followers = initialData.followers;
      this.hashtag = initialData.hashtag
        ? new HashtagInfo(initialData.hashtag)
        : null;
      this.ideas = initialData.ideas;
      this.knowledgeNuggets = initialData.knowledgeNuggets;
      this.replyCount = initialData.replyCount;
      this.rootPostCount = initialData.rootPostCount;
      this.webinars = initialData.webinars;
    }
  }
}
export class Ranking {
  id: string;
  targetUser: UserInfo;
  totalScore: number;
  timestamp: Date;
  month: number;
  year: number;
  rank: number;

  constructor(initialData?: any) {
    this.id = initialData.id;
    this.targetUser = initialData.targetUser;
    this.totalScore = initialData.totalScore;
    this.timestamp = initialData.timestamp;
    this.month = initialData.month;
    this.year = initialData.year;
  }
  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      id: [this.id],
      targetUser: [this.targetUser],
      totalScore: [this.totalScore],
      timestamp: [this.timestamp],
      month: [this.month],
      year: [this.year]
    });
  }
}
export class Contact {
  public userId: string;
  public upn: string;
  public displayName: string;
  public avatarUrl: string;
  public description: string;
  public email: string;
  public phone: string;
  public nameType: string;
  public type: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.userId = initialData.userId;
      this.upn = initialData.upn;
      this.displayName = initialData.displayName;
      this.avatarUrl = initialData.avatarUrl;
      this.description = initialData.description;
      this.email = initialData.email;
      this.phone = initialData.phone;
      this.nameType = initialData.nameType;
      this.type = initialData.type;
    }
  }
  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      userId: [this.userId],
      upn: [this.upn],
      displayName: [this.displayName],
      avatarUrl: [this.avatarUrl],
      description: [this.description],
      email: [this.email],
      phone: [this.phone],
      nameType: [this.nameType],
      type: [this.type]
    });
  }
}

export class KNANode {
  id: number;
  type: ObjectType;
  label: string;
  color: string;
  value: number;
  info: UserInfo | NetworkInfo | HashtagInfo;
  objectGuid: string;
  // x: number;
  // y: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.label = initialData.label;
      this.type = initialData.type;
      this.color = initialData.color;
      this.value = initialData.value;
      this.objectGuid = initialData.objectGuid;
      if (this.type === ObjectType.Network) {
        this.info = new NetworkInfo(initialData.info);
      } else if (this.type === ObjectType.User) {
        this.info = new UserInfo(initialData.info);
      } else if (this.type === ObjectType.Hashtag) {
        this.info = new HashtagInfo(initialData.info);
      }
      // this.x = initialData.x;
      // this.y = initialData.y;
    }
  }
}

export class KNAEdge {
  id: number;
  from: number;
  to: number;
  label: string;

  constructor(initialData?: any) {
    if (initialData) {
      this.id = initialData.id;
      this.from = initialData.from;
      this.to = initialData.to;
      this.label = initialData.label;
    }
  }
}

export interface IconAndLabelInfo {
  label: string;
  iconClass: string;
  // classType?: string;
  btnClass: string;
}

export enum KMSObject {
  User = 1,
  Hashtag = 2,
  Network = 3,
  IK = 4,
  Conversation = 5,
  Idea = 6,
  SuccessStory = 7,
  Challenge = 8,
  WebinarsPerson = 9,
  Webinar = 10,
  FAQ = 11,
  UserInWelcome = 12,
  LessonLearned = 13
}

export enum AttachmentType {
  User = 1,
  Hashtag = 2,
  Network = 3,
  ImpactingKnowledge = 4,
  Conversation = 5,
  FAQ = 6,
  Booking = 7,
  NavLink = 8,
  LessonLearned = 13
}

export enum EntityType {
  // User domain
  User = 1,
  // Hashtag domain
  Hashtag = 10,
  // Conversation domain
  Post = 20,
  // Network domain
  Network = 30,
  // ImpactingKnowledge domain
  KnowledgeNugget = 40,
  Challenge = 41,
  Idea = 42,
  SuccessStory = 43,
  Webinar = 44,
  // Booking domain
  Booking = 50
}

export enum UserType {
  Standard = 1,
  KnowledgeOwner = 2
}

export enum NetworkType {
  CoP = 1,
  BusinessUnit = 2,
  StrategicTag = 3,
  SpecialTag = 4,
  External = 5,
  CoPAndPrivate=8,
  Private = 9
}

export enum DigestFrequency {
  Never = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  RealTime = 5
}


export enum OperationResultStatus {
  Success = 1,
  Failure = 2
}

export enum ActivityType {
  LikePost = 1,
  WritePost = 2,
  FollowUser = 3,
  FollowTag = 4,
  FollowNetwork = 5,
  PromoteKnowledgeNugget = 6,
  PromoteIdea = 7,
  PublishSuccessStory = 8,
  UpvotePost = 9,
  HighlightPost = 10
}

export enum HashtagType {
  Generic = 1,
  Network = 2,
  ImpactingKnowledge = 3,
  BusinessUnit = 4,
  StrategicTag = 5,
  SpecialTag = 6,
  External = 7
}

export enum ObjectType {
  User = 1,
  Hashtag = 2,
  Network = 3,
  ImpactingKnowledge = 4,
  Conversation = 5,
  Idea = 6,
  SuccessStory = 7,
  Challenge = 8,
  Webinar = 9,
  Booking = 10
}

export enum ChoiceType {
  BusinessImpact = 1,
  ExpectedRevenue = 2,
  ContentDeliverer = 3,
  AvailabilityTime = 4,
  DeploymentTime = 5,
  CrucialGap = 6,
  DeploymentCost = 7
}

export enum ParentPageForAvatar {
  ProfileHeader = 1,
  ProfileWidget = 2,
  TimelineRootPost = 3,
  TimelineReply = 4,
  FollowPage = 5,
  RankingPage = 6,
  RankingWidget = 7,
  ContactWidget = 8
}

export enum PeoplePickerUserRestriction {
  None = 1,
  OnlyKO = 2
}

export enum EvaluationFieldCategory {
  AddedValueType = 1,
  InnovationLevel = 2,
  MostlyImpactedArea = 3
}

export enum ImpactingKnowledgeType {
  KnowledgeNugget = 1,
  Challenge = 2,
  Idea = 3,
  SuccessStory = 4,
  Webinar = 5
}

export enum SuccessStoryStatus {
  Draft = 1,
  Proposed = 2,
  Published = 3,
  Rejected = 4
}

export enum LessonLearnedStatus{
  Draft = "6197995c-02df-4075-8cb1-5b286681dc9d",
  Submitted = "df7040cd-9b43-4305-b65e-7d411b0f097a",
  Validated = "a1ee8860-a190-49fd-a3e1-e630e0622d70",
  Approved = "cb2a1985-6605-4b9e-bd43-72d303fc1a3d",
  Implemented = "0d90f67d-3e82-4305-ac50-059a32e32edf",
  NotApproved = "54ced9af-10e7-4e8e-a2f5-1e17693d3d48",
  NotValidated = "c9e1f285-2e73-4f2a-a596-4f865bafbdfc",
  Deleted = "62724deb-dc53-4fa9-bd83-ca29500f9a75"
}

export function translateStatus(statusId:string){
  switch(statusId){
    case LessonLearnedStatus.Draft :
      return "Draft";
    case LessonLearnedStatus.Submitted:
      return "Submitted";
    case LessonLearnedStatus.Approved :
      return "Approved"
    case LessonLearnedStatus.NotApproved :
      return "Not Approved"
    case LessonLearnedStatus.Validated :
      return "Validated";
    case LessonLearnedStatus.NotValidated :
      return "Not Validated";
    case LessonLearnedStatus.Implemented :
      return "Implemented"
    case LessonLearnedStatus.Deleted : 
     return "Deleted";
  }
}

export enum ContactType {
  KMSHelp = 1,
  KMWebinar = 2,
  KMTeam = 3
}

export enum EvaluationObjectType {
  Post = 1,
  Hashtag = 2,
  User = 3,
  Network = 4,
  KnowledgeNugget = 5,
  Challenge = 6,
  Idea = 7,
  SuccessStory = 8,
  FAQ = 9
}

export enum VoteType {
  Standard = 1,
  KnowledgeOwner = 2
}

export enum ApplicationInsightsEvents {
  PostWatched = "post.view"
}
export class RoleNameVariable {
  public static Admin = "Admin";
  public static KMSTeam = "KNATeam";
  public static FocalPoint = "FocalPoint";
  public static Facilitator = "CoPFacilitator";
  public static CoFacilitator = "CoFacilitator";
  public static Champion = "Champion";
  public static KnowledgeOwner = "KnowledgeOwner";
  public static WebinarTeam = "WebinarTeam";
  public static Referent = "Referent";
  public static CoreTeam = "CoreTeam";
  public static KMRefetent = "KMReferent";
}

export interface PostSurvey {
  pollId: string;
  type?: number;
  title?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  questions: Question[];
  created?: string;
  createdBy?: string;
  modified?: string;
  modifiedBy?: string;
}

export interface Question {
  questionId: string;
  type?: number;
  order?: number;
  text: string;
  choices: QuestionChoice[];
  minAnswer?: number;
  maxAnswer?: number;
  required?: boolean;
}

export interface QuestionChoice {
  key: number;
  value: string;
}

export interface SurveyResponse {
  responseId?: string;
  surveyId: string;
  answers: Answer[];
  created?: string;
  createdBy?: string;
  modified?: string;
  modifiedBy?: string;
}

export interface Answer {
  answerId: string;
  questionId: string;
  text?: string;
  choice?: number[];
  number?: number;
}

export interface UserFavorites {
  userId: string;
  conversationIds: Array<string>;
}

export interface ITransaction {
  transactionId: string;
}

export class UserNotification {
  id: string;
  message: string;
  isRead: boolean;
  source: any;
  sourceUser: UserInfo;
  sourceObjectId: string;
  sourceObjectType: number;
  created: Date;
  counter: number;
  sourceUserId: string;
  constructor(initialData: any) {
    if (initialData) {
      this.id = initialData.Id;
      this.message = initialData.message;
      this.isRead = initialData.isRead;
      this.source = initialData.source;
      this.sourceUser = initialData.sourceUser;
      this.sourceObjectId = initialData.sourceObjectId;
      this.sourceObjectType = initialData.sourceObjectType;
      this.created = initialData.created;
      this.counter = initialData.counter;
    }
  }
}

export enum RoleTypes {
  Admin = 1,
  KMTeam = 2,
  CoPFacilitator = 3,
  CoPCoFacilitator = 4,
  BUFocalPoint = 5,
  KnowledgeOwner = 6,
  Champion = 7,
  // Follower = 8,
  // ActiveUser = 9,
  // Visitor = 10,
  BUReferent = 11,
  SpecialTagReferent = 12,
  SpecialTagCoreTeam = 13,
  PrivateNetworkReferent = 14,
  PrivateNetworkCoreTeam = 15,
  KOEligible = 50
}

export enum UserCategory {
  Unknown = 0,
  External = 1,
  Guest = 2,
  Employee = 3
}

export enum NotificationTypeEnum {
  // Unmanaged notification type
  UNKNOWN = 0,
  // Someone mention you in a post
  MENTION = 1,
  // You have been added as an involved people into a KN or Idea
  INVOLVE = 2,
  //  Has been generated an idead for a challenge wich you are an involved people.
  IDEA = 3,
  // Your success story have been published
  PUBLISH_SUCCESS_STORY = 4,
  // Someone like, upvote or kovote your post or reply
  VOTE = 5, // Global setting deprecated
  VOTE_LIKE = 51,
  VOTE_UP = 52,
  VOTE_KO = 53,
  // Someone is following you
  FOLLOW = 6,
  // KM team notification
  KM_TEAM = 7,
  // Someone reply to yur post
  REPLY = 8,
  // Your are a facilitator and your team is promoting
  PROMOTING_KN = 9,
  PROMOTING_CHALLENGE = 10,
  PROMOTING_IDEA = 11,
  PROMOTING_SUCCESS_STORY = 12,
  // Configurations for notification by the tickler job.
  // The challenge is now open.
  OPEN_TICKLER = 13,
  // The challenge is going to close. Reminder
  REMINDER_TICKLER = 14,
  // The challenge is now closed.
  CLOSE_TICKLER = 15,
  // Demote of a Knowledge Nugget
  DEMOTE = 16,
  // Added audience to a post
  AUDIENCE = 17,
  // Voted a poll
  POLL = 18,
  // Results of a Challenge have been published
  PUBLISH_CHALLENGE = 19,

  // Notifiche dei webinar
  WEBINAR_TEAM = 30,
  WEBINAR_INVOLVED_PEOPLE = 31,
  WEBINAR_COP = 32,
  WEBINAR_BU = 33,
  WEBINAR_SPEAKER = 34,
  WEBINAR_PUBLISH = 35,
  WEBINAR_DELETE = 36,
  WEBINAR_MOVE = 37,

  WEBINAR_4PRIVATE_PENDING = 60,
  WEBINAR_4PRIVATE_PUBLISH = 61,
  WEBINAR_4PRIVATE_MOVE = 62,
  PRIVATE_GROUP_POST = 63,
  PRIVATE_GROUP_REPLY = 64,
  PRIVATE_GROUP_ATTACHMENT = 65
}

export enum NotificationChannel {
  NoNotification = 0,
  // tslint:disable-next-line:no-bitwise
  PushNotification = 1 << 0,
  // tslint:disable-next-line:no-bitwise
  MailNotification = 1 << 1,
  MailAndPushNotification = 3
}

export enum NotificationVisibilityEnum {
  All = 0,
  Private = 1,
  Public = 2
}

export class NotificationSetting {
  key: NotificationTypeEnum;
  value: NotificationChannel;
  visibility: NotificationVisibilityEnum;

  constructor(
    key: NotificationTypeEnum,
    value: NotificationChannel,
    visibility: NotificationVisibilityEnum
  ) {
    this.key = key;
    this.value = value;
    this.visibility = visibility;
  }
}

export class PortalConfig {
  public storageImage: [string, string];
  public pushKey: string;
  public instrumentationKey: string;
  public defaultImages: DefaultImage[];
  public bookingMinDaysToBeAutoApproved: number;
}

export class DefaultImage {
  entityType: ImageEntityTypes;
  imageType: ImageType;
  public url: string;
}

export class GuestBadge {
  public guestNetworks: NetworkInfo[];
  public rootPostCount: number;
  public replyCount: number;

  constructor(initialData?: any) {
    if (initialData) {
      this.guestNetworks = initialData.guestNetworks
        ? initialData.guestNetworks.map(u => new NetworkInfo(u))
        : [];
      this.rootPostCount = initialData.rootPostCount;
      this.replyCount = initialData.replyCount;
    }
  }

  toFormGroup(builder: FormBuilder): FormGroup {
    return builder.group({
      rootPostCount: [this.rootPostCount],
      replyCount: [this.replyCount],
      guestNetworks: this.createNetworkInfoArray(this.guestNetworks, builder)
    });
  }

  createNetworkInfoArray(list: NetworkInfo[], builder: FormBuilder) {
    const results = new FormArray([]);

    list.forEach(u => {
      results.push(u.toFormGroup(builder));
    });

    return results;
  }
}

export enum WelcomeExperienceStatus {
  OK = 1,
  AcceptDisclaimer = 2,
  CompleteWelcomeExperience = 3
}

export enum WelcomeExperienceOptionalStep {
  NetworkSelection = 1,
  UserSelection = 2,
  HashtagSelection = 3
}

export enum SearchMode {
  any = "any",
  all = "all"
}

export enum ImageType {
  Generic = 1,
  Avatar = 2,
  Cover = 3,
  Thumbnail = 4
}

export enum ImageEntityTypes {
  User = "user",
  Hashtag = "hashtag",
  Network = "network",
  ExternalNetwork = "externalnetwork",
  KnowledgeNugget = "knowledgenugget",
  Challenge = "challenge",
  Idea = "idea",
  SuccessStory = "successstory",
  Webinar = "webinar",
  NavLink = "navlink",
  Conversation = "conversation"
}

export interface DefaultSearch {
  score: number
  highlights: Highlights
  document: Document
}

export interface Highlights {
  name: string[]
}

export interface Document {
  id: string
  name: string
  description: any
  type: string
  author: string
  logoUrl: any
  url: any
  created: string
  createdYear: number
  modified: string
  modifiedYear: number
  deleted: any
  isDeleted: boolean
  published: any
}

export interface BadgeCounter {
  label: string;
  counter: number;
  list?: any[];
}

export interface Time {
  hours: number;
  minutes: number;
}

export class MapDoc {
  static docToNetInfo(val) {
    const netInfo = new NetworkInfo(val);
    netInfo.networkId = val.id;
    netInfo.type = val.typeId;
    const hash = new HashtagInfo();
    hash.hashtagId = val.hashtagId;
    hash.name = val.hashtag;
    netInfo.hashtag = hash;
    netInfo.logoUrl = val.logoUrl;
    return netInfo;
  }
  static docToHashInfo(val) {
    const hash = new HashtagInfo(val);
    hash.hashtagId = val.id;
    hash.type = val?.typeId;
    // hash.type = this.searchTypeToHashType(val);
    return hash;
  }

 static searchTypeToHashType(val:any){
    switch(val.typeId){
      case "Generic":
        return HashtagType.Generic;
      case "SpecialTag":
        return HashtagType.SpecialTag;
      case "StrategicTag":
        return HashtagType.StrategicTag;
      case "BusinessUnit":
        return HashtagType.BusinessUnit;
    }
  }

  static docToIkInfo(val) {
    const ik = new IkInfo(val);
    ik.id = val.id;
    ik.type = val.typeId;
    ik.name = val.name;
    return ik;
  }
  static docToUserInfo(val) {
    const user = new UserInfo(val);
    user.userId = val.id;
    user.displayName = val.name;
    user.avatarUrl = val.avatarUrl;
    user.unitCode = val.unitCode;
    return user;
  }
}
